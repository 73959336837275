import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { refreshToken } from './refresh.js'

import withStyles from '@material-ui/styles/withStyles'
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Slide,
  TextField,
  Typography,
} from '@material-ui/core'

import { DataGrid } from '@material-ui/data-grid'

import AddCircleIcon from '@mui/icons-material/AddCircle'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit';


import axios from 'axios'

import { grey, red } from '@material-ui/core/colors'

const styles = (theme) => ({
  appBar: {
    position: 'static',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  dialogStyle: {
    maxWidth: '50%',
  },
  error: {
    fontSize: '20px',
    color: red[400],
  },
  floatingButton: {
    //		position: 'fixed',
    bottom: 0,
    right: 0,
  },
  form: {
    width: '98%',
    marginLeft: 13,
    marginTop: theme.spacing(3),
  },
  input: {
    marginBottom: 20,
  },
  patientGrid: {
    // Summer:
    // background: 'linear-gradient(45deg, #cb5921D0 30%, #a99787D0 90%)',
    // Consultus:
    background: 'linear-gradient(45deg, #FF8E53D0 30%, #FE6B8BD0 90%)',
  },
  pos: {
    marginBottom: 12,
  },
  root: {
    minWidth: 470,
  },
  submitButton: {
    display: 'block',
    color: 'white',
    textAlign: 'center',
    position: 'absolute',
    right: 10,
  },
  subTitle: {
    fontSize: '22px',
    width: 188,
    color: grey[700],
    top: 20,
    bottom: 20,
    // Consultus:
    background: 'linear-gradient(45deg, #FF8E53 30%, #FE6B8B 90%)',
    // Summer:
    // background: 'linear-gradient(45deg, #cb5921D0 30%, #a99787D0 90%)',
    borderRadius: '8px',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  toolbar: theme.mixins.toolbar,
  uiProgess: {
    position: 'fixed',
    zIndex: '1000',
    height: '31px',
    width: '31px',
    left: '50%',
    top: '35%',
  },
  viewRoot: {
    margin: 0,
    padding: theme.spacing(2),
  },
})

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" {...props} ref={ref} />
))


let patient0 = {
  id: Math.random(),
  SAID: '',
  companyName: '',
  dateOfBirth: '',
  dependentCode: '',
  email: '',
  firstName: '',
  gender: '',
  homeAddrCode: '',
  homeAddress: '',
  homeTel: '',
  married: '',
  medicalAid: '',
  memberNo: '',
  mobile: '',
  nationality: '',
  nextOfKin: '',
  nextOfKinNo: '',
  occupation: '',
  patientName: '',
  planType: '',
  principalAddrCode: '',
  principalAddress: '',
  principalFirstName: '',
  principalHomeTel: '',
  principalId: '',
  principalMember: '',
  principalMobile: '',
  principalName: '',
  principalWorkTel: '',
  referringDoctor: '',
  referringDrNo: '',
  workAddress: '',
  workAddrCode: '',
  workTel: '',
}

class patient extends Component {
  constructor(props) {
    super(props)

    this.state = {
      buttonType: '',
      deleteModeOn: false,
      deleteInfo: 0,
      displaySubMenuButtons: 0,
      error: false,
      errors: [],
      fieldname: '',
      fieldvalue: null,
      filter: '',
      login: false,
      mouseX: null,
      mouseY: null,
      open: false,
      patients: [],
      patient: {},
      uiLoading: true,
      userfeedback: '',
      viewOpen: false,
    }
  }

  fetchPatients = () => {
    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }
    axios
      .get('/api/patients')
      .then((response) => {
        this.setState({
          patients: response.data,
          uiLoading: false,
        })
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.setState({ login: true })
        }
        else {
          this.setState({ uiLoading: false })
          console.log(error)
        }
      })
  }

  componentDidMount = () => {
      const r = refreshToken()
      if (r === 403) {
        this.setState({login:true})
      }
      else {
        this.fetchPatients()
      }    
  }



  handleChange = (event) => {
    const patient = {...this.state.patient};
    patient[event.target.name] = event.target.value;
    this.setState({patient});

    // this.setState({
    //   [event.target.name]: event.target.value,
    // })
  }

  render() {
    if (this.state.login === true) {
      return <Redirect
        to={{ pathname: "/login" }}
      />
    }

    const { classes } = this.props
    const { open, errors, patient } = this.state

    const handleClickToCreate = () => {
      this.setState({
        buttonType: 'Create',
        deleteInfo:0,
        displaySubMenuButtons:0,
        open: true,
        patient:patient0
      })
    }

    const handleClickToEdit = () => {
      this.setState({
        errors: {},
        mouseX: null,
        mouseY: null,
        open: true,
        buttonType: 'Edit'
      })
    }
  
    const handleClose = (event) => {
      this.setState({ 
        open: false,
        mouseX: null,
        mouseY: null,
       })
    }

    const handleDoubleClick = (event) => {
      const mouseX = this.state.mouseX ? null : event.clientX - 2
      const mouseY = this.state.mouseY ? null : event.clientY - 4
      this.setState({
        displaySubMenuButtons:0,
        mouseX, 
        mouseY
      })
    }

    // const handleRightClick = (event) => {
    //   event.preventDefault()
    //   this.setState({
    //     mouseX: event.clientX - 2,
    //     mouseY: event.clientY - 4,
    //   })
    // }


    const handleSingleClick = (event) => {
      this.setState({displaySubMenuButtons:1})
    }


    const handleCMenuClose = () => {
      this.setState({
         displaySubMenuButtons:0,
         mouseX: null,
         mouseY: null 
      })
    }

    const fetchKey = (event) => {
      this.setState({
        deleteInfo:0,
        patient: event.row,
      })
    }

    const handleCreateOrEditPatient = (event) => {
      event.preventDefault()
      let {id,SAID,firstName,patientName, ...userpatient} = this.state.patient

      errors.SAID = (SAID === '')
      errors.firstName = (firstName === '')
      errors.patientName = (patientName === '')
      this.setState({errors})
      if (errors.SAID || errors.firstName || errors.patientName) {
        return
      }
      userpatient = {
        ...userpatient,
        SAID: SAID.replace(/[ ]/g, ''),
        firstName: firstName.replace(/[ ]/g, ''),
        patientName: patientName.replace(/[ ]/g, ''),
      }

      
      let options = {}
      if (this.state.buttonType === 'Edit') {
        options = {
          url: `/api/patient/${id}`,
          method: 'put',
          data: userpatient,
        }
      } else {
        for (const p of this.state.patients) {
          if (SAID === p.SAID) {
            errors.SAID = true
            this.setState({userfeedback:' !!! Duplicate SAID detected !!!'})
            return
          }
        }
        this.setState({userfeedback:''})
        options = {
          url: '/api/patient',
          method: 'post',
          data: userpatient,
        }
      }
      const authToken = localStorage.getItem('AuthToken')
      axios.defaults.headers.common = { Authorization: `${authToken}` }
      this.setState({uiLoading:true})
      axios(options)
        .then((response) => {
          let patients = []
          if (options.method === 'post') {
            const patient = response.data
            this.setState({
              buttonType:'Edit',
              patient
            })
            patients = [...this.state.patients]
            patients.unshift(patient)
          }
					else {  // if (options.method === 'put') {
            this.setState({open:false})
            Object.assign(userpatient, {id: `${id}`})
            patients = this.state.patients.map((patient) => {
              return patient.id === userpatient.id ? userpatient : patient
            })
          }
          this.setState({ uiLoading: false, patients })
        })
        .catch( (error) => {
            if (error.response.status === 403) {
              this.setState({login:true})
            }
            else {
              this.setState({ uiLoading: false })
              console.log(error)
            }
        })
    }

    const handleClickToDelete = () => {
      const authToken = localStorage.getItem('AuthToken')
      axios.defaults.headers.common = { Authorization: `${authToken}` }

      this.setState({ uiLoading: true })
      axios
          .delete(`/api/patient/${patient.id}`)
          .then(() => {
            const patients = [...this.state.patients.filter((x) => x.id !== patient.id)]
            this.setState({ 
              deleteInfo:1,
              displaySubMenuButtons:0, 
              mouseX:null,
              mouseY:null,
              open:false,
              uiLoading: false,
              patients,
            })
          })
          .catch((error) => {
            if (error.response.status === 403) {
              this.setState({ login: true })
            }
            else {
              this.setState({ uiLoading: false })
              console.log(error)
            }            
          })
    }


    // const handleClickToDelete = () => {
    //   const authToken = localStorage.getItem('AuthToken')
    //   axios.defaults.headers.common = { Authorization: `${authToken}` }
    //   this.setState({uiLoading:true})
    //   for (const patientId of this.state.selectedRows) {
    //     this.setState({uiLoading:true})
    //     axios
    //       .delete(`/api/patient/${patientId}`)
    //       .then(() => {
    //         const patients = this.state.patients.filter((x) => x.id !== patientId)
    //         this.setState({ 
    //           uiLoading:false, 
    //           deleteInfo:1,
    //           patients })
    //       })
    //       .catch( (error) => {
              // if (error.response.status === 403) {
              //   this.setState({login:true})
              // }
              // this.setState({ 
              //   uiLoading: false, 
              //   console.log(error)
              // })
    //       })
    //   }
    //   this.setState({ 
    //       deleteModeOn: false,
    //       selectedRows: null 
    //   })
    // }
  
    // const handleSelectionChange = (e) => {
    //   this.setState({ deleteModeOn: e.length > 0 })
    //   this.setState({ selectedRows: e })
    // }

    const patientColumns = [
      {
        field: 'patientName',
        headerName: 'Surname',
        width: 170,
        editable: false,
      },
      {
        field: 'firstName',
        headerName: 'First Name',
        width: 170,
        editable: false,
      },
      {
        field: 'SAID',
        headerName: 'ID/Passport number',
        width: 170,
        editable: false,
      },
      {
        field: 'medicalAid',
        headerName: 'Medical Aid',
        width: 170,
        editable: false,
      },
    ]

    const Error =
      this.state.error === false ? (
        <div className={classes.subTitle}>&nbsp;PATIENTS LIST</div>
      ) : (
        <div className={classes.error}>{this.state.error}</div>
      )

    // const addButton = (
    //   <IconButton
    //     className={classes.floatingButton}
    //     color="action"
    //     aria-label="Add patient"
    //     onClick={handleClickToCreate}
    //     size="large"
    //   >
    //     <AddCircleIcon style={{ fontSize: 60 }} />
    //   </IconButton>
    // )

    const deleteInfo = this.state.deleteInfo ? (
      <Button
        className={classes.floatingButton}
      >
        Delete Successful  
      </Button>
    ) : ''

    const subMenuButtons = this.state.displaySubMenuButtons ? (
      <div>
        <IconButton
          className={classes.floatingButton}
          color="action"
          aria-label="Add patient"
          onClick={handleClickToCreate}
          size="large"
        >
          <AddCircleIcon style={{ fontSize: 60 }} />
        </IconButton>
        <IconButton
            className={classes.floatingButton}
            color="action"
            aria-label="Patient Details"
            onClick={handleClickToEdit}
            size="large"
        >
          <EditIcon style={{ fontSize: 60 }} />
        </IconButton>
        <IconButton
          className={classes.floatingButton}
          color="action"
          aria-label="Delete patient"
          onClick={handleClickToDelete}
          size="large"
        >
          <DeleteIcon style={{ fontSize: 60 }} />
        </IconButton>
        {deleteInfo}
      </div>
    ) : (
      <div>
        <IconButton
        className={classes.floatingButton}
        color="action"
        aria-label="Add patient"
        onClick={handleClickToCreate}
        size="large"
      >
        <AddCircleIcon style={{ fontSize: 60 }} />
      </IconButton>
      {deleteInfo}
    </div>
    )

    // const deleteButton = this.state.deleteModeOn ? (
    //   <IconButton
    //     className={classes.deleteButton}
    //     color="primary"
    //     aria-label="delete patient"
    //     onClick={handleClickToDelete}
    //     size="large"
    //   >
    //     <DeleteIcon style={{ fontSize: 60 }} />
    //   </IconButton>
    // ) : (
    //   ''
    // )

    const contextMenu = (
      <Menu
        className={classes.contextMenu}
        keepMounted
        open={this.state.mouseY !== null}
        onClose={handleCMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          this.state.mouseY !== null && this.state.mouseX !== null
            ? { top: this.state.mouseY, left: this.state.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleClickToEdit}>Patient Details</MenuItem>
        <MenuItem >---------------</MenuItem>
        <MenuItem onClick={handleClickToDelete}>Delete Patient</MenuItem>
      </Menu>
    )


    if (this.state.uiLoading === true) {
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this.state.uiLoading && (
            <CircularProgress size={150} className={classes.uiProgess} />
          )}
        </main>
      )
    } else {
      return (
        <main className={classes.content}>
          <div className={classes.toolbar} />
            {Error}
            {subMenuButtons}
          <div
//            onContextMenu={handleRightClick}
            onDoubleClick={handleDoubleClick}
            onClick={handleSingleClick}
            style={{ cursor: 'context-menu' }}
          >
            {contextMenu}
            <div style={{ width: '540pt', height: '480pt'}}>
              <DataGrid
                className={classes.patientGrid}
                rows={this.state.patients}
                columns={patientColumns}
                isRowSelectable={() => true}
                // checkboxSelection
                // disableSelectionOnClick
                // onSelectionModelChange={handleSelectionChange}
                // onCellDoubleClick={handleClickToEdit}
                onCellClick={fetchKey}
              />
            </div>
          </div>
          <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
          >
						<AppBar position="static">
              <Toolbar variant="dense">
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  {this.state.buttonType === 'Edit'
                    ? 'Edit a patient'
                    : 'Create a new patient' + this.state.userfeedback}
                </Typography>
                <Button
                  autoFocus
                  color="inherit"
                  onClick={handleCreateOrEditPatient}
                  className={classes.submitButton}
                >
                  {this.state.buttonType === 'Edit' ? 'Save' : 'Submit'}
                </Button>
              </Toolbar>
            </AppBar>

            <form className={classes.form} noValidate>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    id="gender"
                    label="Title"
                    name="gender"
                    autoComplete="gender"
                    helperText={errors.gender}
                    value={this.state.patient.gender}
                    error={errors.gender ? true : false}
                    onChange={this.handleChange}
                  >
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    required
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    autoComplete="firstName"
                    helperText={errors.firstName}
                    value={this.state.patient.firstName}
                    error={errors.firstName ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    required
                    id="patientName"
                    label="Surname"
                    name="patientName"
                    autoComplete="patientName"
                    helperText={errors.patientName}
                    value={this.state.patient.patientName}
                    error={errors.patientName ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    required
                    id="dateOfBirth"
                    label="Date of birth"
                    name="dateOfBirth"
                    autoComplete="dateOfBirth"
                    type="date"
                    helperText={errors.dateOfBirth}
                    value={this.state.patient.dateOfBirth}
                    error={errors.dateOfBirth ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    required
                    id="SAID"
                    label="ID/Passport number"
                    name="SAID"
                    autoComplete="SAID"
                    helperText={errors.SAID}
                    value={this.state.patient.SAID}
                    error={errors.SAID ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
                {/* <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    required
                    id="nationality"
                    label="Nationality"
                    name="nationality"
                    autoComplete="nationality"
                    helperText={errors.nationality}
                    value={this.state.patient.nationality}
                    error={errors.nationality ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid> */}
                {/* <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    required
                    id="married"
                    label="Marital status"
                    name="married"
                    autoComplete="married"
                    helperText={errors.married}
                    value={this.state.patient.married}
                    error={errors.married ? true : false}
                    onChange={this.handleChange}
                    select
                  >
                    <MenuItem value="true">married</MenuItem>
                    <MenuItem value="false">not married</MenuItem>
                  </TextField>
                </Grid> */}
                {/* <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    id="occupation"
                    label="Occupation"
                    name="occupation"
                    autoComplete="occupation"
                    helperText={errors.occupation}
                    value={this.state.patient.occupation}
                    error={errors.occupation ? true : false}
                    onChange={this.handleChange}
                  /> 
                </Grid> */}
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    required
                    id="homeAddress"
                    label="Home Address"
                    name="homeAddress"
                    helperText={errors.homeAddress}
                    value={this.state.patient.homeAddress}
                    error={errors.homeAddress ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    required
                    id="homeAddrCode"
                    label="Home Address Code"
                    name="homeAddrCode"
                    autoComplete="homeAddrCode"
                    helperText={errors.homeAddrCode}
                    value={this.state.patient.homeAddrCode}
                    error={errors.homeAddrCode ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    id="homeTel"
                    label="Home Tel"
                    name="homeTel"
                    autoComplete="homeTel"
                    helperText={errors.homeTel}
                    value={this.state.patient.homeTel}
                    error={errors.homeTel ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    id="workTel"
                    label="Work Tel"
                    name="workTel"
                    autoComplete="workTel"
                    helperText={errors.workTel}
                    value={this.state.patient.workTel}
                    error={errors.workTel ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    id="mobile"
                    label="Mobile Number"
                    name="mobile"
                    autoComplete="mobile"
                    helperText={errors.mobile}
                    value={this.state.patient.mobile}
                    error={errors.mobile ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    helperText={errors.email}
                    value={this.state.patient.email}
                    error={errors.email ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
                {/* <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    id="companyName"
                    label="Company Name"
                    name="companyName"
                    autoComplete="companyName"
                    helperText={errors.companyName}
                    value={this.state.patient.companyName}
                    error={errors.companyName ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid> */}
                {/* <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    id="workAddress"
                    label="Work Address"
                    name="workAddress"
                    size="medium"
                    helperText={errors.workAddress}
                    value={this.state.patient.workAddress}
                    error={errors.workAddress ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid> */}
                {/* <Grid item xs={3} sm={6}>
                  <TextField
                    variant="outlined"
                    id="workAddrCode"
                    label="Work Address Code"
                    name="workAddrCode"
                    autoComplete="workAddrCode"
                    helperText={errors.workAddrCode}
                    value={this.state.patient.workAddrCode}
                    error={errors.workAddrCode ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid> */}
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    required
                    id="medicalAid"
                    label="Medical Aid"
                    name="medicalAid"
                    autoComplete="medicalAid"
                    helperText={errors.medicalAid}
                    value={this.state.patient.medicalAid}
                    error={errors.medicalAid ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    required
                    id="planType"
                    label="Plan Type"
                    name="planType"
                    autoComplete="planType"
                    helperText={errors.planType}
                    value={this.state.patient.planType}
                    error={errors.planType ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    required
                    id="memberNo"
                    label="Membership Number"
                    name="memberNo"
                    autoComplete="memberNo"
                    helperText={errors.memberNo}
                    value={this.state.patient.memberNo}
                    error={errors.memberNo ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    required
                    id="dependentCode"
                    label="Dependent Code"
                    name="dependentCode"
                    autoComplete="dependentCode"
                    helperText={errors.dependentCode}
                    value={this.state.patient.dependentCode}
                    error={errors.dependentCode ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    id="principalMember"
                    label="Principal Member"
                    name="principalMember"
                    autoComplete="principalMember"
                    helperText={errors.principalMember}
                    value={this.state.patient.principalMember}
                    error={errors.principalMember ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    id="principalId"
                    label="Principal ID"
                    name="principalId"
                    autoComplete="principalId"
                    helperText={errors.principalId}
                    value={this.state.patient.principalId}
                    error={errors.principalId ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
                {/* <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    id="principalName"
                    label="Principal Surname"
                    name="principalName"
                    autoComplete="principalName"
                    helperText={errors.principalName}
                    value={this.state.patient.principalName}
                    error={errors.principalName ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid> */}
                {/* <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    id="principalFirstName"
                    label="Principal First Name"
                    name="principalFirstName"
                    autoComplete="principalFirstName"
                    helperText={errors.principalFirstName}
                    value={this.state.patient.principalFirstName}
                    error={errors.principalFirstName ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid> */}
                {/* <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    id="principalAddress"
                    label="Principal Address"
                    name="principalAddress"
                    autoComplete="principalAddress"
                    helperText={errors.principalAddress}
                    value={this.state.patient.principalAddress}
                    error={errors.principalAddress ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid> */}
                {/* <Grid item xs={3} sm={9}>
                  <TextField
                    variant="outlined"
                    id="principalAddrCode"
                    label="Principal Code"
                    name="principalAddrCode"
                    autoComplete="principalAddrCode"
                    helperText={errors.principalAddrCode}
                    value={this.state.patient.principalAddrCode}
                    error={errors.principalAddrCode ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid> */}
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    id="principalHomeTel"
                    label="Principal Home Tel"
                    name="principalHomeTel"
                    autoComplete="principalHomeTel"
                    helperText={errors.principalHomeTel}
                    value={this.state.patient.principalHomeTel}
                    error={errors.principalHomeTel ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
                {/* <Grid item xs={3}>
                  <TextField
                    variant="outlined"
                    id="principalWorkTel"
                    label="Principal Work Tel"
                    name="principalWorkTel"
                    autoComplete="principalWorkTel"
                    helperText={errors.principalWorkTel}
                    value={this.state.patient.principalWorkTel}
                    error={errors.principalWorkTel ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid> */}
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    id="principalMobile"
                    label="Principal Mobile"
                    name="principalMobile"
                    autoComplete="principalMobile"
                    helperText={errors.principalMobile}
                    value={this.state.patient.principalMobile}
                    error={errors.principalMobile ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    required
                    id="nextOfKin"
                    label="Next of Kin"
                    name="nextOfKin"
                    autoComplete="nextOfKin"
                    helperText={errors.nextOfKin}
                    value={this.state.patient.nextOfKin}
                    error={errors.nextOfKin ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    required
                    id="nextOfKinNo"
                    label="Next of Kin Number"
                    name="nextOfKinNo"
                    autoComplete="nextOfKinNo"
                    helperText={errors.nextOfKinNo}
                    value={this.state.patient.nextOfKinNo}
                    error={errors.nextOfKinNo ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    required
                    id="referringDoctor"
                    label="Referring Doctor"
                    name="referringDoctor"
                    autoComplete="referringDoctor"
                    helperText={errors.referringDoctor}
                    value={this.state.patient.referringDoctor}
                    error={errors.referringDoctor ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    required
                    id="referringDrNo"
                    label="Referring Doctor Number"
                    name="referringDrNo"
                    autoComplete="referringDrNo"
                    helperText={errors.referringDrNo}
                    value={this.state.patient.referringDrNo}
                    error={errors.referringDrNo ? true : false}
                    onChange={this.handleChange}
                  />
                </Grid>
              </Grid>
            </form>
          </Dialog>
        </main>
      )
    }
  }
}

export default withStyles(styles)(patient)
