import React, { Component } from 'react';
import logo from './img/logo.png';

class Logo extends Component {
  render() {
    return (
      <div className="logo-main">
        <img src={logo} alt="Logo" />
      </div>
    )
  }
}

export default Logo;