import axios from 'axios'

// function to refresh firebase auth token
export function refreshToken() {
  // console.log(localStorage.getItem('TokenExpiry'))
  const tokenExpiry = Number(localStorage.getItem('TokenExpiry'))

  const go = Math.trunc((Date.now() / 1000) + 3000) // ...refresh token if older than 10min
  // console.log ('refreshToken: ', go, ' > ' , tokenExpiry,'?')
  
  if (go > tokenExpiry) {
    // console.log('refresh!')
    const authToken = localStorage.getItem('AuthToken')
    axios.defaults.headers.common = { Authorization: `${authToken}` }
    axios
      .get('/api/refresh')
      .then((response) => {
        localStorage.setItem('AuthToken', `Bearer ${response.data.token}`)
        const exp = go + 600 // firebase token will expire in NOW + 1h
        localStorage.setItem('TokenExpiry', exp)
      })
      .catch((error) => {
        // alert('Your session has expired. Please login again.', error)
        if(error.response.status === 403) {
          return(403)
        }
      });
  }
  return 200
}  
